"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _myDAPPwalletProvider_connectId, _myDAPPwalletProvider_callback;
Object.defineProperty(exports, "__esModule", { value: true });
exports.myDAPPwalletProvider = void 0;
const ethers_1 = require("ethers");
const logger_1 = require("@ethersproject/logger");
const _version_1 = require("./_version");
const web_1 = require("@ethersproject/web");
const properties_1 = require("@ethersproject/properties");
const logger = new logger_1.Logger(_version_1.version);
const bindEvent = function (element, eventName, eventHandler) {
    if (element.addEventListener) {
        element.addEventListener(eventName, eventHandler, false);
    }
    else if (element.attachEvent) {
        element.attachEvent(`on${eventName}`, eventHandler);
    }
};
function checkError(method, error, params) {
    throw error;
}
class myDAPPwalletProvider extends ethers_1.ethers.providers.JsonRpcProvider {
    constructor(url, apiKey) {
        super({ url, headers: { apikey: apiKey } });
        _myDAPPwalletProvider_connectId.set(this, void 0);
        _myDAPPwalletProvider_callback.set(this, void 0);
        __classPrivateFieldSet(this, _myDAPPwalletProvider_connectId, window.localStorage.getItem("ConnectId"), "f");
        __classPrivateFieldSet(this, _myDAPPwalletProvider_callback, {}, "f");
        bindEvent(window, "message", this.eventHandler.bind(this));
    }
    eventHandler(event) {
        const { data } = event;
        if (data.target === "mdw-inpage") {
            const callback = __classPrivateFieldGet(this, _myDAPPwalletProvider_callback, "f")[data.id];
            if (callback) {
                switch (data.event) {
                    case "mdw-connect":
                        if (data.result) {
                            __classPrivateFieldSet(this, _myDAPPwalletProvider_connectId, data.result.connectId, "f");
                            this.user = data.result.user;
                            window.localStorage.setItem("ConnectId", data.result.connectId);
                            if (callback) {
                                callback(data.error, data.result.wallet.address);
                                delete __classPrivateFieldGet(this, _myDAPPwalletProvider_callback, "f")[data.id];
                                break;
                            }
                        }
                    default:
                        if (callback) {
                            callback(data.error, data.result);
                            delete __classPrivateFieldGet(this, _myDAPPwalletProvider_callback, "f")[data.id];
                        }
                }
            }
        }
    }
    event(event, err, result) {
        if (result && result.info) {
            event.info = result.info;
        }
        if (err) {
            event.error = err;
        }
        else if (result && result.error) {
            event.error = result.error;
        }
        else {
            event.result = result.result;
        }
        // eslint-disable-next-line default-case
        switch (event.target) {
            case "mydappshop-inpage":
                if (window.opener) {
                    window.opener.postMessage(event, "*");
                }
                break;
        }
    }
    getResult(payload) {
        if (payload.event) {
            this.event(payload.event, payload.error, payload.result);
        }
        if (payload.error) {
            // @TODO: not any
            const error = new Error(payload.error.message);
            error.code = payload.error.code;
            error.data = payload.error.data;
            throw error;
        }
        else if (payload.window) {
            if (!this.window || this.window.closed) {
                this.window = window.open(payload.window.url, payload.window.target, payload.window.windowFuture + ",top=250, left=250");
            }
            else {
                this.window.location = payload.window.url;
            }
            const result = new Promise((resolve, reject) => {
                __classPrivateFieldGet(this, _myDAPPwalletProvider_callback, "f")[payload.id] = function (error, result) {
                    if (error) {
                        reject(logger.makeError(error.message, error.code, error.data));
                    }
                    else {
                        resolve(result);
                    }
                };
            }).then((result) => {
                this.emit("debug", {
                    action: "response",
                    response: result,
                    provider: this
                });
                return result;
            }).catch((error) => {
                throw error;
            });
            return result;
        }
        return payload.result;
    }
    send(method, params) {
        const request = {
            method: method,
            params: params,
            id: (this._nextId++),
            jsonrpc: "2.0"
        };
        this.emit("debug", {
            action: "request",
            request: (0, properties_1.deepCopy)(request),
            provider: this
        });
        // We can expand this in the future to any call, but for now these
        // are the biggest wins and do not require any serializing parameters.
        const cache = (["eth_chainId", "eth_blockNumber"].indexOf(method) >= 0);
        if (cache && this._cache[method]) {
            return this._cache[method];
        }
        let connection = { ...this.connection };
        if (__classPrivateFieldGet(this, _myDAPPwalletProvider_connectId, "f")) {
            connection.headers = { ...connection.headers, "ConnectId": __classPrivateFieldGet(this, _myDAPPwalletProvider_connectId, "f") };
        }
        const result = (0, web_1.fetchJson)(connection, JSON.stringify(request), this.getResult.bind(this)).then((result) => {
            this.emit("debug", {
                action: "response",
                request: request,
                response: result,
                provider: this
            });
            return result;
        }, (error) => {
            if (error.error) {
                this.emit("debug", {
                    action: "response",
                    error: error.error,
                    request: request,
                    provider: this
                });
                throw error.error;
            }
            else {
                this.emit("debug", {
                    action: "response",
                    error: error,
                    request: request,
                    provider: this
                });
                throw error;
            }
        });
        // Cache the fetch, but clear it on the next event loop
        if (cache) {
            this._cache[method] = result;
            setTimeout(() => {
                delete this._cache[method];
            }, 0);
        }
        return result;
    }
    prepareRequest(method, params) {
        switch (method) {
            case "connect":
                return ["connect", []];
            case "disconnect":
                return ["disconnect", []];
            default:
                return super.prepareRequest(method, params);
        }
    }
    async connect() {
        await this.getNetwork();
        const result = await this.perform("connect", {});
        try {
            return result;
        }
        catch (error) {
            throw error;
        }
    }
    async disconnect() {
        await this.getNetwork();
        const result = await this.perform("disconnect", {});
        try {
            window.localStorage.removeItem("ConnectId");
            __classPrivateFieldSet(this, _myDAPPwalletProvider_connectId, null, "f");
            return result;
        }
        catch (error) {
            throw error;
        }
    }
}
exports.myDAPPwalletProvider = myDAPPwalletProvider;
_myDAPPwalletProvider_connectId = new WeakMap(), _myDAPPwalletProvider_callback = new WeakMap();
